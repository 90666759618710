import React from "react";

const RespondingLink = ({text, className}) => {
    return <a target="_blank"
              rel="no-follow"
              className={className}
              href="https://forms.gle/rrkGmVXkRX31eS1o8">
        {text}
    </a>;
};
export default RespondingLink;