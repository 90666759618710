import { useState, useEffect } from 'react'

export const useScroll = (width = 767, upSensitivity = 30, downSensitivity = 5) => {
    const [scroll, setScroll] = useState({
        x: 0,
        y: 0,
        direction: ''
    })

    const listener = e => {
        setScroll(prev => {
            const current = {
                x: document.body.getBoundingClientRect().left,
                y: -document.body.getBoundingClientRect().top,
                direction: prev.y > -document.body.getBoundingClientRect().top ? 'up' : 'down'
            }

            const diff = Math.abs(current.y - prev.y);
            switch(current.direction){
                case "up":
                    if(diff <= upSensitivity){
                        current.direction = prev.direction;
                    }
                    break;

                case "down":
                    if(diff <= downSensitivity){
                        current.direction = prev.direction;
                    }
                    break;
            }
            return current;
        });
    }

    useEffect(() => {
        if(document.body.getBoundingClientRect().width <= width){
            window.addEventListener('scroll', listener);
            return () => window.removeEventListener('scroll', listener);
        }
        return () => {};
    }, [])

    return scroll;
}