import {Actions} from "../constants/actions";
import avatarom from "../api/avatarom";

export const getRegions = () =>
    ({type: Actions.GET_REGIONS, payload: avatarom.Geo.getCities()});

export const getProfessions = () =>
    ({type: Actions.GET_PROFESSIONS, payload: avatarom.Vacancies.getProfessions()});

export const getTags = () =>
    ({type: Actions.GET_TAGS, payload: avatarom.Vacancies.getTags()});

export const getCategories = () =>
    ({type: Actions.GET_CATEGORIES, payload: avatarom.Vacancies.getCategories()});

export const setRegion = (region) =>
    ({type: Actions.SET_REGION, region: region});

export const setProfession = (profession) =>
    ({type: Actions.SET_PROFESSION, profession: profession});