import React from "react"
import {connect, useDispatch, useStore} from "react-redux";
import { useRouter } from 'next/router'
import "./SearchBase.less";
import ProfessionsSearch from "../Professions/ProfessionsSearch";
import RegionsSearch from "../Regions/RegionsSearch";
import Tools from "../../../tools/Tools";
import {searchVacancies, setSearchParameters} from "../../../actions/vacancyActions";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

const mapStateToProps = state => (
    {
        parameters: state.vacancies.parameters,
    }
);

const getSearchLink = (region, profession, parameters) => {
    let link = "/rabota";
    if(region && region.title){
        link+= `/${Tools.transliterate(region.title)}`;
        if(profession && profession.title){
            link+= `/${Tools.transliterate(profession.title)}`;
        }
    }
    if(parameters.text){
        link+= `?query=${parameters.text}`
    }
    return link;
}

const SearchBase = ({parameters, inline = false, clearable = false}) => {

    const store = useStore();
    const dispatch = useDispatch();
    const router = useRouter();

    const applySearch = async () => {
        const options = store.getState().options;
        const newParameters = store.getState().vacancies.parameters;
        const link = getSearchLink(options.region, options.profession, newParameters);
        await router.push("/rabota/[...slug]", link);
        Tools.scrollTop();
    };

    const professionChanged = async (data) => {
        if(data && data.id){
            dispatch(setSearchParameters({
                ...parameters,
                text: data.id === -1 ? data.title: "",
                professionId: null
            }));
            await applySearch();
        }
    }

    const regionChanged = async (data) => {
        if(data && data.title){
            dispatch(setSearchParameters({
                ...parameters,
                cityId: data.id
            }));
            if(inline){
                await dispatch(searchVacancies(store));
            }
            else{
                await applySearch();
            }
        }
    }

    return (
        <div className="search-base">
            <ProfessionsSearch clearable={clearable} onChange={professionChanged} query={parameters.text} />
            <RegionsSearch onChange={regionChanged} />

            {inline
                ? <Button onClick={async () => await applySearch()}
                          icon={"search"}
                          className="button-white search-button">
                </Button>
                : null
            }
    </div>)
}

export default connect(mapStateToProps)(SearchBase)