import React from "react";
import Link from "next/link";
import {Grid, Image} from "semantic-ui-react";
import Logo from "../../../../public/images/logo-secondary.svg";
import SearchBase from "../Base/SearchBase";
import "./PanelSearch.less";
import EmployerLink from "../../Shared/Links/EmployerLink";

const PanelSearch = ({activeTab = PanelSearch.Tabs.NewVacancies, ...rest}) => {

    const linkClass = (value, tab) => {
        return value === tab ? "link active" : "link"
    }

    const employerLink = <EmployerLink text={"Работодателю"}
                                       className={linkClass(activeTab, PanelSearch.Tabs.Employer)} />

    return (
        <div {...rest} className="panel-search">
            <Grid className="content" verticalAlign="middle" centered>
                <Grid.Column computer={2} mobile={5}>
                    <Link href="/" as={"/"}>
                        <a>
                            <Image className={"logo"} src={Logo} alt={"Аватаром найти работу"} />
                        </a>
                    </Link>
                    <div className="employer-link">
                        {employerLink}
                    </div>
                </Grid.Column>
                <Grid.Column computer={2} only='computer'>
                    <Link href="/" as={"/"}>
                        <a className={linkClass(activeTab, PanelSearch.Tabs.NewVacancies)}>
                            Новые вакансии
                        </a>
                    </Link>
                </Grid.Column>

                <Grid.Column computer={8} tablet={11} mobile={11} className="search-controls">
                    <SearchBase clearable={true}  />
                </Grid.Column>

                <Grid.Column computer={3} only='computer'>
                    {employerLink}
                </Grid.Column>

            </Grid>
        </div>
    );
}

PanelSearch.Tabs = {
    NewVacancies: 0,
    Employer: 1,
    Login: 2
};

export default PanelSearch;