import React from "react";

const EmployerLink = ({text, className}) => {
    return <a target="_blank"
              rel="nofollow"
              className={className}
              href="https://forms.gle/S4ReHsvsgeAmebTZ6">
        {text}
    </a>;
};
export default EmployerLink;