import React from "react";
import {connect, useDispatch} from "react-redux";
import ItemsSearch from "../Base/ItemsSearch";
import {setProfession} from "../../../actions/optionsActions";

const mapStateToProps = state => (
    {
        professions: state.options.professions,
        value: state.options.profession
    }
);

const ProfessionsSearch = ({professions, value, query, onChange, clearable = false}) => {

    const dispatch = useDispatch();

    let initialValue = {
        title: query
    };

    if(value && value.title){
        initialValue = value;
    }

    return (
        <ItemsSearch value={initialValue}
                     items={professions}
                     onChange={(data) => {
                         console.log("NEW: ", data);
                         dispatch(setProfession(data));
                         if(onChange){
                             onChange(data);
                         }
                     }}
                     placeholder={"Поиск вакансий"}
                     className={"professions-search"}
                     textSearch={true}
                     resultsCount={6}
                     clearable={clearable}
                     icon={null} />
    );
}

export default connect(mapStateToProps)(ProfessionsSearch)