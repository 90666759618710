import React from "react";
import {connect, useDispatch} from "react-redux";
import "./RegionsSearch.less";
import ItemsSearch from "../Base/ItemsSearch";
import {setRegion} from "../../../actions/optionsActions";

const mapStateToProps = state => (
    {
        regions: state.options.regions,
        value: state.options.region,
    }
);

const setRegionCookie = (region) => {
    if(region && region.id){
        setTimeout(() => document.cookie = `regionId=${region.id}; path=/` , 10);
    }
};

const RegionsSearch = ({regions, value, onChange}) => {
    const dispatch = useDispatch();

    return <ItemsSearch value={value}
                        items={regions}
                        onChange={
                            (data) => {
                                setRegionCookie(data);
                                dispatch(setRegion(data));
                                if(onChange){
                                    onChange(data);
                                }
                            }
                        }
                        placeholder={"Город"}
                        className={"regions-search"}
                        resultsCount={7}
                        icon={"map marker alternate"} />
}

export default connect(mapStateToProps)(RegionsSearch)