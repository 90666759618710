import React, {useState} from "react";
import {Icon, Button, Label, Search} from "semantic-ui-react";
import Tools from "../../../tools/Tools";

const ItemsSearch = ({items, value, onChange, placeholder, className, icon, resultsCount = 5, textSearch = false, clearable = false}) => {

    const search = (text = "") => {
        const canonicalQuery = text.toLowerCase();
        let filtered = items.filter(p => p.title.toLowerCase().includes(canonicalQuery));

        if(filtered.length > resultsCount){
            filtered = filtered.slice(0, resultsCount);
        }
        else if(filtered.length < resultsCount){
            filtered = [...filtered, ...items.slice(0, resultsCount - filtered.length)];
        }
        if(textSearch === true && text && text.length > 0){
            filtered = [{
                id: ItemsSearch.TEXT_RESULT,
                title: text,
                istext: "true"
            },
                ...filtered];
        }
        return Tools.distinct(filtered, item => item.id);
    }

    const changed = (value) => {
        if(onChange){
            onChange(value);
        }
    }

    const [query, setQuery] = useState(value.title);
    const [results, setResults] = useState(search(value.title));
    const [open, setOpen] = useState(false);

    return (
        <div>
        <Search placeholder={placeholder}
                noResultsMessage={"Ничего не найдено"}
                value={query}
                open={open}
                onMouseDown={() => setOpen(true)}
                onFocus={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                onSearchChange={(e, {value}) => {
                    if(!open){
                        setOpen(true);
                    }
                    const result = search(value);

                    setResults(result);
                    setQuery(value);
                }}
                results={results}
                onResultSelect={(e, {result}) => {
                    setOpen(false);
                    setQuery(result.title);
                    changed(result);
                }}
                className={className ? `items-search ${className}` : "items-search"}
                minCharacters={0}
                resultRenderer={({title, count, istext}) => {
                    return istext === "true"
                        ? <span>искать везде: <span className="text-search">«{title}»</span></span>
                        : <span>{title} <Label circular className="search-items-count" size="tiny">{count}</Label></span>
                 }
                }
                onKeyUp={(e) => {
                    if(textSearch){
                        if(e.keyCode === ItemsSearch.ENTER_BUTTON_CODE){
                            setOpen(false);
                            const data = results[0];
                            if(data && data.id === ItemsSearch.TEXT_RESULT && data.title === query) {
                                onChange(data);
                            }
                        }
                    }
                }}
                icon={icon} >
        </Search>
            {
                clearable && query
                    ? (<Button icon basic className={"clearable-button"} onClick={() => {
                        setQuery("");
                        changed({
                            id: ItemsSearch.CLEAR_RESULT,
                            title: ""
                        });
                    }}>
                        <Icon name={"remove"}/>
                    </Button>)
                    : null
            }
        </div>
    );
}

ItemsSearch.TEXT_RESULT = -1;
ItemsSearch.CLEAR_RESULT = -2;
ItemsSearch.ENTER_BUTTON_CODE = 13;

export default ItemsSearch