import React from "react";
import PanelSearch from "../../Search/Panel/PanelSearch";
import "./Navbar.less";
import {useScroll} from "../../../hooks/useScroll";

const Navbar = ({user = {}, ...rest}) => {

    const scroll = useScroll();

    return (
        <nav {...rest} className={scroll && scroll.direction === "down" && scroll.y > 120 ? "navbar mini" : "navbar"}>
            <PanelSearch />
        </nav>
    );
}
export default Navbar;